import { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import DetailForm from "./DetailForm";
import Table from "./table/Datatable";

export const ApprovalForm=()=>{

    const [record,setRecord]=useState(null);
    const [value, setValue] = useState(new Date().getTime());


    const updateRecord=(rec)=>{
        setRecord(rec)
    }

    function reloadComponent(){
        setRecord(null)
        setValue(new Date().getTime());
    }

    return(


        <div style={{height:"100%"}}>
            {value &&

<Container fluid>
      <Row>
        <Col sm={record?9:12}><Table onSelectRecord={updateRecord} updateTime={value}/></Col>
        {
           record && <Col sm={3} style={{background:"#efefef" }}><DetailForm onSelectRecord={updateRecord} record={record} refresh={reloadComponent}/></Col>
        }
      </Row>
    </Container>
            
    }
            </div>
    
    );

}