import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import Select from 'react-select';
import './custom2.css';

import axios from 'axios'
import Header from './Header';
import Login from './Login';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
  Redirect
} from "react-router-dom";
import Dashboard from './Dashboard';
import { Downloads } from './Downloads';

function getUser(){
  let userInfo = localStorage.getItem("userInfo");
  if(userInfo){
      userInfo = JSON.parse(userInfo);
  }
  return userInfo;
}


function App() {

  const isLoggedIn = localStorage.getItem('userInfo');

  const isTokenVerified = true;
  
  const [user,setUser] = useState(getUser())

  
const ProtectedRoute=({ component: Component , user, ...rest })=> {

    let userInfo = localStorage.getItem("userInfo");
    if(userInfo){
      userInfo = JSON.parse(userInfo);
    }
    const isLogged = userInfo?.username;

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isLogged? <Component user {...rest}  /> : <Navigate to="/login" />;
}



function logout(){
  localStorage.removeItem("userInfo");
  setUser(null);
}
  

  return (
    <>
      <Header user={user} logout={logout}/>

      <Router>
        <Routes>

        <Route exact path='/home' element={<ProtectedRoute component={Dashboard}/>}/>
        
        <Route exact path='/' element={<ProtectedRoute component={Dashboard}/>}/>
        
        <Route  path="/login"
            element={<Login setUser={setUser} />}  />
        </Routes>

      
      </Router>


    </>
  );

}

// <div>
//       <Header/>
//       {isLoggedIn
//         ?
//           <BodyComp/>
//         : <Login/>
//       }

// </div>

export default App;
