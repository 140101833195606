import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SimpleMap from './SimpleMap';
import { FaArrowLeft, FaCheck, FaCross, FaTimes } from 'react-icons/fa';
import { userInfo } from './UserInfo';
import { put,deleteRecord } from './Api';
import SweetAlert from 'react-bootstrap-sweetalert';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';

export default function OneDRideAccountForm(props) {
  const [status, setStatus] = useState(props.record.subsStatus==true?"Active":"Inactive");
  const [licenceNumber, setLicenceNumber] = useState(props.record.licenceNumber);
  const [vehicleNumber, setVehicleNumber] = useState(props.record.vehicleNumber);

  
  const [note, setNote] = useState("");
  const [isDelete, setDelete] = useState(false);

  const onchangeStatus=(evt)=>{
    setStatus(evt.target.value)
  }

  useEffect(() => {
    // Update the document title using the browser API
    setStatus(props.record.subsStatus==true?"Active":"Inactive")
    setLicenceNumber(props.record.licenceNumber);
    setVehicleNumber(props.record.vehicleNumber)
  },[props.record.id]);


  const saveData=()=>{

    const record = Object.assign({},props.record);
    record.notes=record.notes||[];
    delete record.username;
    record.notes.unshift({
      oldStatus: record.status,
      subsStatus: status=="Active",
      message: note,
      time: new Date().getTime(),
      updateBy: userInfo.firstName,
      updateByUser: userInfo.username
    })
    record.subsStatus=status=="Active";
    record.licenceNumber=licenceNumber;
    record.vehicleNumber=vehicleNumber;
    const partnerId=userInfo.partnerId||"zrider";
    put("/accounts/"+props.record.username+"?partnerId="+partnerId,record).then(resp=>{
      props.refresh();
    })
  }


  const deleteConfirm=()=>{
    setDelete(true);
  }
  const deleteData=()=>{


    const partnerId=userInfo.partnerId||"zrider";
    deleteRecord("/objects/"+partnerId+"_account/"+props.record.id+"?partnerId="+partnerId,null).then(resp=>{
      props.refresh();
    })
  }

  return (
    <div style={{ display: 'block', 
                  width: "100%",
                  height:(window.innerHeight-74)+"px",
                  padding: 10 }}>

      {
        isDelete  && 
        <SweetAlert style={{color:"black"}}
	warning
	showCancel
	confirmBtnText="Yes, Proceed!"
	confirmBtnBsStyle="danger"
	title="Data will get deleted permanantly. Are you sure?"
	onConfirm={()=>{setDelete(false);deleteData()}}
	onCancel={()=>{setDelete(false)}}
	focusCancelBtn
  >
	
  </SweetAlert>
      }
      <Form>
      <Form.Group style={{height:"60px"}}>
      <Row xs="auto">
        <Col> <Button style={{width:"100%"}} variant="info"  onClick={()=>props.onSelectRecord(null)}>
          <FaArrowLeft></FaArrowLeft> Close
        </Button>
        </Col>
        <Col>
        <Button variant="danger"  type="button"  onClick={()=>deleteConfirm()}>
            <FaTimes></FaTimes> Delete
        </Button>
        </Col>
        <Col>
        <Button  variant="primary" type="button"  onClick={()=>saveData()}>
            <FaCheck></FaCheck> Save
        </Button>
        </Col>
      </Row>
       
       
        
      </Form.Group>
      <Form.Group>
          <Form.Label>Subscription Status:</Form.Label>
          <Form.Control as="select" value={status} onChange={onchangeStatus}>
        <option value="Inactive">Inactive</option>
        <option value="Active">Active</option>
        
     </Form.Control>
     </Form.Group>
     <Form.Group>
          <Form.Label>Licence Number:</Form.Label>
          <Form.Control  value={licenceNumber} onChange={e=>setLicenceNumber(e.target.value)}/>
        
        
        </Form.Group>
        <Form.Group>
          <Form.Label>Vehicle Number:</Form.Label>
          <Form.Control  value={vehicleNumber} onChange={e=>setVehicleNumber(e.target.value)}/>
        
        
        </Form.Group>
        <Form.Group>
          <Form.Label>Note</Form.Label>
         
          <Form.Control as="textarea" rows="3" onChange={e=>setNote(e.target.value)} />
        </Form.Group>
        
        <Form.Group>
          <Form.Label>Location</Form.Label>
          <div>
            <SimpleMap record={props.record}/>
          </div>
        </Form.Group>
        
      </Form>
    </div>
  );
}