import React, { useMemo, useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
import axios from 'axios'
import { API_BASE_PATH, post } from "../Api";

const Table = props => {
    const columns = [
        {
            name: "Request Id",
            selector: "id",
            sortable: true,
            
        },
        {
            name: "Vehicle Number",
            selector: "vehicleNumber",
            sortable: true,
            
        },
        {
            name: "Vehicle Type",
            selector: "vehicleType",
            sortable: true,
            
        },
        {
            name: "Vehicle Image",
            selector: "vehicleImageURL",
            sortable: true,
            cell: row =>
            (
                <>
                    <img style={{width:"60px",height:"60px",objectFit:"cover",borderRadius:"50%"}} src={row.vehicleImageURL}/>
                      
                </>
            ) 
            
        },
        {
            name: "Driver Image",
            selector: "driverImageURL",
            sortable: true,
            cell: row =>
            (
                <>
                    <img style={{width:"60px",height:"60px",objectFit:"cover",borderRadius:"50%"}} src={row.driverImageURL}/>
                      
                </>
            ) 
            
        },
        {
            name: "Phone",
            selector: "phone",
            sortable: true,
            
        },
        {
            name: "Status",
            selector: "status",
            sortable: true,
            
        },
        {
            name: "  Buttons  ",
            button: true,
            width: "140px" ,    
            cell: row =>
                (
                    <>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => props.onSelectRecord(row)}
                            style={{ marginRight: "0px",background:"black",marginRight:"2px"}}
                        >View Details</button>
                    </>
                ) 
        }
    ];
    const [data, setData] = useState([]);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const filteredItems = data;
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    var userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
    }
    
    const onDataFilter=val=>{
        setFilterText(val);
        fetchUsers(1,val);
    }

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                onDataFilter("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => onDataFilter(e.target.value)}
                onFilterText={e => onDataFilter(filterText)}
                onSaveFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

   

    const fetchDataOC=async (page,filter) =>{
        const response = await post(`/objects/zrider_enquiries/search?partnerId=${userInfo.partnerId}`,{
            searchFilter:{
                vehicleNumber:filter,
                phone:filter
            }
        });
        setData(response.rows.map(s=>{
            s.data.id=s.dataKey;
            s.data.status=s.data.status||"Pending";
            return s.data;
        }));
        let count = response.total;
        
        setTotalRows(count);
        return response.data;
    }

    const fetchUsers = async (page,filt) => {
        setLoading(true);
        await fetchDataOC(page,filt);

        setPerPage(10);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        // setLoading(true);
        // const response = await axios.get(`/objects/outletClusters?lob=${userInfo.accountId}&page=${page}&count=${newPerPage}&resolve=true`);
        // setData(response.data.data);
        // setTotalRows(response.data.length+100);
        // //setPerPage(newPerPage);
        // setLoading(false);
    };

    useEffect(() => {
        // alert("Calling")

        fetchUsers(1); // fetch page 1 of users
    }, [props.updateTime]);

    return (
        <DataTable
            
            title=""
            columns={columns}
            data={filteredItems}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponent}
        />
    );
};




export default Table;
